import axios from "axios";
import { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
//import NavDropdown from "react-bootstrap/NavDropdown";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Offcanvas from "react-bootstrap/Offcanvas";
import { FileIcon } from "react-file-icon";
import Table from "react-bootstrap/Table";
import qamgi from '../src/nl2659-logo-amagi_0.png'
import qwestL from '../src/Plan-de-travail-7@4x.png'
function App() {

    const [cont, setCont] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL_SERVER}/epg/api`)
            .then((res) => {
                setCont(res.data.message);
                console.log(res.data.message);
            })
            .catch((err) => console.log(err)
                );
    }, [open]);

    const [feed, setFeed] = useState('1');
    const handleChangeFeed = e => setFeed(e.target.value);

    const [star_date, setStar_date] = useState('');
    const handleChangeStar_date = e => setStar_date(e.target.value);

    const [end_date, setEnd_date] = useState('');
    const handleChangeEnd_date = e => setEnd_date(e.target.value);


    function dateIsValid(dateStr) {
        const regex = /^\d{4}-\d{2}-\d{2}$/;

        if (dateStr.match(regex) === null) {
            return false;
        }

        const date = new Date(dateStr);

        const timestamp = date.getTime();

        if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            return false;
        }

        return date.toISOString().startsWith(dateStr);
    }

    const gener = () => {
        setOpen(true)
        if(!dateIsValid(star_date) || !dateIsValid(end_date) || (star_date>end_date)){
            alert("start date is greater than end date or one of dates is invalid!")
            setOpen(false)
        }else {
            //if((star_date instanceof Date) && (end_date instanceof Date))
            //    alert("is date")
            axios
                .get(`${process.env.REACT_APP_URL_SERVER}/epg/api/${feed}/${star_date}/${end_date}`,)
                .then(res =>{alert(`EPG ${feed} is generated between ${star_date} and ${end_date}`)
                    setOpen(false)
                })
                .catch(err => {
                    console.log(err)

                    alert(`EPG ${feed} will be generated between ${star_date} and ${end_date}`)
                    setOpen(false)


                })
        }


    }

    const del = (str) => {
        let dell=window.confirm("Are you sure you want to delete this EPG?");
        if(dell){
            setOpen(true)
            axios
                .get(`${process.env.REACT_APP_URL_SERVER}/epg/api/del/${str}`,)
                .then(res => {alert(res.data.message)
                    setOpen(false)}
                )
                .catch(err => {
                    console.log(err)
                    setOpen(false)
                })
        }

    }



    return (
    <div >
        <Navbar key={"md"} bg="light" expand={"md"} className="mb-3 ">
            <Container fluid>
                <img src={qwestL} alt="qwestL" style={{ height: 70 }}/>
                <Navbar.Brand href="#">EPG Amagi</Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-md`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                            EPG Generator
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="m-2 justify-content-end flex-grow-1 pe-3">
                            <Form.Select
                                style={{ width: 100 }}
                                aria-label="Default select example"
                                value={feed}
                                onChange={handleChangeFeed}
                            >
                                <option value="1">Mix</option>
                                <option value="2">Classic</option>
                                <option value="3">Jazz</option>
                            </Form.Select>
                        </Nav>
                        <Form className="m-2 d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Start date"
                                className="me-2"
                                aria-label="Start date"
                                value={star_date}
                                onChange={handleChangeStar_date}
                            />
                            <Form.Control
                                type="search"
                                placeholder="End date"
                                className="me-2"
                                aria-label="End date"
                                value={end_date}
                                onChange={handleChangeEnd_date}
                            />
                            <Button variant="outline-success" onClick={gener}>Gener</Button>


                        </Form>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
        <div>

        </div>
        <div className="d-flex" >
            <div className="w-75">
        <Table striped bordered hover className=" mx-2 ">
            <thead>
            <tr>
                <th></th>
                <th>Start date</th>
                <th>End date</th>
                <th style={{ width: 70 }}>#</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody className="text-center">

                {cont.map((e,i) => {

                    return [
                        <tr key={i}>
                            <td style={{ width: 50 }}>
                                <FileIcon
                                    size={48}
                                    color="#1A754C"
                                    labelColor="#1A754C"
                                    labelUppercase
                                    type="spreadsheet"
                                    glyphColor="rgba(255,255,255,0.4)"
                                    extension="xls"
                                />
                            </td>
                            <td>{e.toString().slice(6,10)+"-"+e.toString().slice(10,12)+"-"+e.toString().slice(12,14)}</td>
                            <td>{e.toString().slice(15,19)+"-"+e.toString().slice(19,21)+"-"+e.toString().slice(21,23)}</td>
                            <td className="50">{(e.toString().slice(4,5)==="1")?"Mix":((e.toString().slice(4,5)==="2")?"Classic":"Jazz")}</td>
                            <td><Button variant="outline-primary" href={`${process.env.REACT_APP_URL_SERVER}/epg/api/${e}`}> <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                       height="16" fill="currentColor"
                                                                       className="bi bi-download" viewBox="0 0 16 16">
                                <path
                                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path
                                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                            </svg></Button>{' '}<Button variant="outline-danger" onClick={() => del(e)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-trash" viewBox="0 0 16 16">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fillRule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg></Button></td>

                        </tr>
                    ]
                })}
            </tbody>
        </Table>
            </div>
            <div className="w-25 h-100 text-center">
                <Snackbar
                    anchorOrigin={ {vertical: 'bottom', horizontal: 'right'} }
                    open={open}
                    autoHideDuration={600}

                    key={'bottom right'}
                >
                    <Alert  severity="info" sx={{ width: '100%' }}>
                        The operation is in progress!
                    </Alert>
                </Snackbar>

                <img className="img-fluid"  src={qamgi} alt={"qamagi"}/>

            </div>

        </div>


    </div>
  );
}

export default App;
